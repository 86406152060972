import { Box, Button } from "@mui/material";
import { useRouter } from "next/router";
import GoogleSearchBar from "./GoogleSearchBar";
import DrawerMenu from "./DrawerMenu";
import { links, sublinks } from "./Utils/utils";
import Image from "next/image";

// Styles
const styles = {
  navbarContainer: {
    position: "sticky",
    top: 0,
    zIndex: 1050,
    background: "#FEFEFE",
  },
  logoContainer: {
    px: 3,
    py: 1,
    flex: 1,
  },
  logoLarge: {
    display: { xs: "none", md: "block" },
  },
  logoSmall: {
    display: { xs: "block", md: "none" },
  },
  searchBarContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    minWidth: { xs: 350, sm: 600, md: 350 },
  },
  linksBar: {
    display: { xs: "none", md: "flex" },
    background: "#14213d",
    justifyContent: "center",
    flexWrap: "wrap",
    py: 1,
  },
  sublinksBar: {
    display: { xs: "none", md: "flex" },
    background: "#e5e5e5",
    justifyContent: "center",
    flexWrap: "wrap",
    py: 1,
  },
  linkButton: {
    color: "#FEFEFE",
    mx: 3,
    minWidth: 130,
  },
  sublinkButton: {
    color: "black",
    mx: 3,
    minWidth: 130,
  },
  mobileDivider: {
    display: { xs: "flex", md: "none" },
    height: 8,
    background: "#14213d",
  },
};

export default function Navbar() {
  const router = useRouter();

  return (
    <Box sx={styles.navbarContainer}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={styles.logoContainer}>
          {/* Large Screen Logo */}
          <Box sx={styles.logoLarge}>
            <Image
              alt="Kentucky Climate Center's Logo"
              src="/KCC-ARTP-WKU-Wide-2.webp"
              width={410}
              height={85}
              style={{ cursor: "pointer" }}
              onClick={() => router.push("/")}
            />
          </Box>
          {/* Small Screen Logo */}
          <Box sx={styles.logoSmall}>
            <Image
              alt="Kentucky Climate Center's Logo"
              src="/KCC-Logo.webp"
              width={165}
              height={85}
              style={{ cursor: "pointer" }}
              onClick={() => router.push("/")}
            />
          </Box>
        </Box>
        {/* Drawer Menu */}
        <DrawerMenu links={links} sublinks={sublinks} />
        {/* Search Bar */}
        <Box sx={styles.searchBarContainer}>
          <Box sx={{ flex: 1 }}>
            <GoogleSearchBar />
          </Box>
        </Box>
      </Box>
      {/* Mobile Divider */}
      <Box sx={styles.mobileDivider} />
      {/* Primary Links */}
      <Box sx={styles.linksBar}>
        {links.map((link) => (
          <Button
            size="small"
            key={`${link.label}_key`}
            href={link.link}
            sx={styles.linkButton}
          >
            {link.label}
          </Button>
        ))}
      </Box>
      {/* Sublinks */}
      <Box sx={styles.sublinksBar}>
        {sublinks.map((sublink) => (
          <Button
            size="small"
            key={`${sublink.label}_key`}
            href={sublink.link}
            sx={styles.sublinkButton}
          >
            {sublink.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
}
