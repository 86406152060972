import { useEffect, useState } from "react";

export default function GoogleSearchBar() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    document.head.append(script);
    script.src = "https://cse.google.com/cse.js?cx=f000bdc43b5b545b7";
    setIsReady(true);
  }, []);

  return isReady && <div className="gcse-search" />;
}
