import { Box, Typography } from "@mui/material";
import Link from "next/link";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useRouter } from "next/router";
import { links, sublinks, legalLinks } from "./Utils/utils";
import Image from "next/image";

// Centralized styles
const styles = {
  footerContainer: {
    background: "#efefef",
    color: "",
    minHeight: 150,
  },
  footerTop: {
    display: "flex",
    flexWrap: "wrap-reverse",
    pt: 2,
    px: 5,
  },
  logoSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    my: 3,
    textAlign: { xs: "center", md: "left" },
    borderBottom: { xs: 3, md: 0 },
  },
  logoImage: {
    cursor: "pointer",
  },
  logoText: {
    pl: 1,
  },
  socialIconsContainer: {
    flex: 1,
    py: 2,
    pl: 1,
    display: "flex",
    justifyContent: { xs: "center", md: "start" },
  },
  socialIcon: {
    mx: 1,
  },
  linksSection: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    pl: 1,
    my: 3,
  },
  linkColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  linkHeader: {
    pb: 1,
  },
  linkItem: {
    p: 0.2,
    color: "#666",
  },
  footerBottom: {
    textAlign: "center",
    pb: 3,
  },
};

export default function Footer() {
  const router = useRouter();
  const nowYear = new Date().getFullYear();

  return (
    <Box sx={styles.footerContainer}>
      {/* Top Section */}
      <Box sx={styles.footerTop}>
        {/* Logo and Social Media */}
        <Box sx={styles.logoSection}>
          <Box>
            <Image
              alt="Kentucky Climate Center's Logo"
              src="/KCC-Logo.webp"
              width={180}
              height={92}
              style={styles.logoImage}
              onClick={() => router.push("/")}
            />
            <Typography variant="h6" sx={styles.logoText}>
              Kentucky Climate Center
            </Typography>
            <Typography variant="body1" sx={styles.logoText}>
              Measuring Weather. Establishing{" "}
              <span style={{ color: "#cf152d" }}>Climate</span>.
            </Typography>
          </Box>
          <Box sx={styles.socialIconsContainer}>
            <Link href="https://www.facebook.com/KYClimate/" title="Facebook">
              <FacebookIcon sx={styles.socialIcon} />
            </Link>
            <Link href="https://twitter.com/KYClimate" title="Twitter">
              <TwitterIcon sx={styles.socialIcon} />
            </Link>
            <Link
              href="https://www.youtube.com/channel/UCDg3h8leOZa0wHap90rX5Rw"
              title="YouTube"
            >
              <YouTubeIcon sx={styles.socialIcon} />
            </Link>
          </Box>
        </Box>

        {/* Links */}
        <Box sx={styles.linksSection}>
          {/* Links Columns */}
          <Box sx={{ flex: 1, display: "flex" }}>
            {/* Main Links */}
            <Box sx={styles.linkColumn}>
              <Typography variant="h6" sx={styles.linkHeader}>
                Links
              </Typography>
              {links.map((link, idx) => (
                <Box key={`Links_${idx}`} sx={styles.linkItem}>
                  <Link href={link.link}>{link.label}</Link>
                </Box>
              ))}
            </Box>
            {/* Sublinks */}
            <Box sx={styles.linkColumn}>
              <Typography variant="h6" sx={styles.linkHeader}>
                Climate
              </Typography>
              {sublinks.map((sublink, idx) => (
                <Box key={`Climate_${idx}`} sx={styles.linkItem}>
                  <Link href={sublink.link}>{sublink.label}</Link>
                </Box>
              ))}
            </Box>
            {/* Legal Links */}
            <Box sx={styles.linkColumn}>
              <Typography variant="h6" sx={styles.linkHeader}>
                Legal
              </Typography>
              {legalLinks.map((legalLink, idx) => (
                <Box key={`Legal_${idx}`} sx={styles.linkItem}>
                  <Link href={legalLink.link}>{legalLink.label}</Link>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Bottom Section */}
      <Box sx={styles.footerBottom}>
        <Typography>© {nowYear} Copyright Kentucky Climate Center</Typography>
        <Link href={router.pathname}>
          <Typography>Back To Top</Typography>
        </Link>
      </Box>
    </Box>
  );
}
