import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useRouter } from "next/router";

export default function DrawerMenu({ links = [], sublinks = [] }) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const list = (items) => {
    return (
      <List>
        {items.map((i) => (
          <ListItem
            key={i.label}
            disablePadding
            onClick={() => {
              router.push(i.link);
            }}
          >
            <ListItemButton>
              <ListItemText primary={i.label} sx={{ pl: 2 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  const getMenu = () => (
    <Box
      sx={{
        width: 250,
        borderLeft: 5,
        height: "100%",
        borderColor: "#14213d",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {list(links)}
      <Divider />
      {list(sublinks)}
    </Box>
  );

  return (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
        justifyContent: "flex-end",
        alignItems: "center",
        flex: 1,
        mr: 1,
      }}
    >
      <Button
        sx={{ width: 100, height: 100 }}
        size="large"
        aria-haspopup="true"
        onClick={toggleDrawer(true)}
        color="inherit"
      >
        <MenuIcon sx={{ fontSize: 40 }} />
      </Button>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        {getMenu()}
      </Drawer>
    </Box>
  );
}
