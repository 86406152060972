import { Box } from "@mui/material";
import Head from "next/head";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useRouter } from "next/router";
import { ConvertPathText } from "./Utils/utils";

export default function Page({ children, sx, className }) {
  const router = useRouter();
  const linkPath = router.asPath.split("/");
  const pageName = linkPath[linkPath.length - 1];
  let title = "";
  if (pageName) {
    title = ConvertPathText(pageName) + " | ";
  }
  return (
    <Box className={className}>
      <Head>
        <meta charSet="UTF-8"></meta>
        <title>{`${title}Kentucky Climate Center`}</title>
        <meta
          property="og:title"
          content={`${title}Kentucky Climate Center`}
        ></meta>
        {title === "" ? (
          <meta
            name="description"
            content="The Kentucky Climate Center (KCC) is the state's official climate office, responsible for the collection, dissemination, and assessment of weather and climate information across the Commonwealth. Located at Western Kentucky University, the KCC leads research, educational, and outreach activities in collaboration with federal, state, and local governments; private sector firms; universities; and non-profit organizations."
          ></meta>
        ) : (
          <meta
            name="description"
            content={`Learn more about ${title.replace(
              " | ",
              ""
            )} on the Kentucky Climate Center website.`}
          ></meta>
        )}
        <meta
          name="keywords"
          content={`Kentucky Climate Center, Kentucky Mesonet, State's official Climate office, climate, weather,${title.replace(
            " | ",
            ""
          )}`}
        ></meta>
      </Head>
      <Box
        sx={[
          sx,
          {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            background: "#FEFEFE",
            color: "#333333",
          },
        ]}
      >
        <Navbar></Navbar>
        <Box sx={{ flex: 1 }}>{children}</Box>
        <Footer></Footer>
      </Box>
    </Box>
  );
}
